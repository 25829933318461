import api from '@/api/companies'
import { findIndex } from 'lodash'

const namespaced = true

const state = {
  current: null,
  companies: null,
  supermetrics_counts: null
}

// getters
const getters = {
  current: state => {
    return state.current
  },
  list: state => {
    return state.companies
  },
  supermetrics_counts: state => {
    return state.supermetrics_counts
  }
}

// actions
const actions = {
  refresh({ commit }) {
    api.listCompanies(data => {
      commit('setCompanies', data.companies)
      commit('setSupermetricsCounts', data.supermetrics_counts)
    })
  },
  clear({ commit }) {
    commit('clearCompanies');
  },
  add({ commit }, payload) {
    commit('addCompany', payload);
  },
  update({ commit }, payload) {
    commit('updateCompany', payload);
  },
  remove({ commit }, payload) {
    commit('removeCompany', payload);
  },
  set({ commit }, payload) {
    commit('setCompany', payload);
  }
}

// mutations
const mutations = {
  setCompanies(state, payload) {
    state.companies = payload;
  },
  setSupermetricsCounts(state, payload) {
    state.supermetrics_counts = payload;
  },
  clearCompanies(state) {
    state.companies = null;
  },
  addCompany(state, payload) {
    state.companies = [...state.companies, payload]
  },
  updateCompany(state, payload) {
    let index = findIndex(state.companies, { slug: payload.slug });
    state.companies.splice(index, 1, payload);
    if (state.current && payload.slug === state.current.slug) {
      state.current = payload;
    }
  },
  removeCompany(state, payload) {
    let index = findIndex(state.companies, { slug: payload });
    state.companies.splice(index, 1);
  },
  setCompany(state, payload) {
    if (payload === null) {
      state.current = null;
    } else {
      let index = findIndex(state.companies, { slug: payload });
      state.current = state.companies[index];
    }

  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
