<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Supermetrics Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-supermetrics-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-alert
      title="Warning!"
      description="Some metrics are only reported daily, not hourly. If a selected metric produces all zeros when records are expected, uncheck 'Reported Hourly' and process again."
      type="warning"
      :closable="false"
      style="margin-bottom: 1rem"
    ></el-alert>

    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <div v-for="(service, key) in services" :key="key">
        <el-checkbox v-model="service.selected" style="margin-bottom: 0.5rem">{{
          service.name
        }}</el-checkbox>

        <div v-if="service.selected">
          <el-row>
            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first('services.' + key + '.accounts')
                    ? form.errors.first('services.' + key + '.accounts')
                    : null
                "
                label="Accounts"
                required
              >
                <modal-select>
                  <el-select
                    v-model="service.accounts"
                    :style="{ width: '100%' }"
                    @focus="form.errors.clear('services.' + key + '.accounts')"
                    filterable
                    multiple
                  >
                    <el-option
                      v-for="account in accounts[service.metricsKey]"
                      :key="account.external_id"
                      :label="account.name"
                      :value="account.external_id"
                    ></el-option>
                  </el-select>
                </modal-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first('services.' + key + '.startDate')
                    ? form.errors.first('services.' + key + '.startDate')
                    : null
                "
                label="Start Date"
                required
              >
                <el-date-picker
                  v-model="service.startDate"
                  type="date"
                  align="center"
                  format="M/d/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  clearable
                  :picker-options="pickerOptions"
                  @focus="form.errors.clear('services.' + key + '.startDate')"
                ></el-date-picker>
                <p class="help-text">
                  The date from which to collect data. Please set this as close
                  to the start of the data as possible to avoid extra API calls.
                </p>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first(
                    'services.' + key + '.metrics.impressions.field'
                  )
                    ? form.errors.first(
                        'services.' + key + '.metrics.impressions.field'
                      )
                    : null
                "
                label="Impressions"
                required
              >
                <modal-select>
                  <el-select
                    v-model="service.metrics.impressions.field"
                    :style="{ width: '100%' }"
                    @focus="
                      form.errors.clear(
                        'services.' + key + '.impressions.field'
                      )
                    "
                    filterable
                  >
                    <el-option-group
                      v-for="(group, key) in fields.metrics[service.metricsKey]"
                      :key="key"
                      :label="key"
                    >
                      <el-option
                        v-for="metric in group"
                        :key="metric.field_id"
                        :label="metric.name"
                        :value="metric.field_id"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                </modal-select>
                <el-checkbox v-model="service.metrics.impressions.hourly"
                  >Reported Hourly</el-checkbox
                >
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first('services.' + key + '.metrics.clicks.field')
                    ? form.errors.first(
                        'services.' + key + '.metrics.clicks.field'
                      )
                    : null
                "
                label="Clicks"
                required
              >
                <modal-select>
                  <el-select
                    v-model="service.metrics.clicks.field"
                    :style="{ width: '100%' }"
                    @focus="form.errors.clear('services.' + key + '.clicks')"
                    filterable
                  >
                    <el-option-group
                      v-for="(group, key) in fields.metrics[service.metricsKey]"
                      :key="key"
                      :label="key"
                    >
                      <el-option
                        v-for="metric in group"
                        :key="metric.field_id"
                        :label="metric.name"
                        :value="metric.field_id"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                </modal-select>
                <el-checkbox v-model="service.metrics.clicks.hourly"
                  >Reported Hourly</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first(
                    'services.' + key + '.metrics.conversions.field'
                  )
                    ? form.errors.first(
                        'services.' + key + '.metrics.conversions.field'
                      )
                    : null
                "
                label="Conversions"
                required
              >
                <modal-select>
                  <el-select
                    v-model="service.metrics.conversions.field"
                    :style="{ width: '100%' }"
                    @focus="
                      form.errors.clear('services.' + key + '.conversions')
                    "
                    filterable
                  >
                    <el-option-group
                      v-for="(group, key) in fields.metrics[service.metricsKey]"
                      :key="key"
                      :label="key"
                    >
                      <el-option
                        v-for="metric in group"
                        :key="metric.field_id"
                        :label="metric.name"
                        :value="metric.field_id"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                </modal-select>
                <el-checkbox v-model="service.metrics.conversions.hourly"
                  >Reported Hourly</el-checkbox
                >
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                :error="
                  form.errors.first('services.' + key + '.metrics.cost.field')
                    ? form.errors.first(
                        'services.' + key + '.metrics.cost.field'
                      )
                    : null
                "
                label="Cost"
                required
              >
                <modal-select>
                  <el-select
                    v-model="service.metrics.cost.field"
                    :style="{ width: '100%' }"
                    @focus="form.errors.clear('services.' + key + '.cost')"
                    filterable
                  >
                    <el-option-group
                      v-for="(group, key) in fields.metrics[service.metricsKey]"
                      :key="key"
                      :label="key"
                    >
                      <el-option
                        v-for="metric in group"
                        :key="metric.field_id"
                        :label="metric.name"
                        :value="metric.field_id"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                </modal-select>
                <el-checkbox v-model="service.metrics.cost.hourly"
                  >Reported Hourly</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Filters">
            <div
              v-for="(filter, filterKey) in service.filters"
              :key="'filters-row_' + filterKey"
            >
              <el-row style="margin-bottom: 0.5rem">
                <el-col
                  class="el-form-item"
                  :class="{
                    'is-error': form.errors.has(
                      'services.' + key + '.filters.' + filterKey + '.field'
                    ),
                  }"
                  :span="7"
                  style="padding-right: 5px"
                >
                  <modal-select>
                    <el-select
                      v-model="filter.field"
                      :style="{ width: '100%' }"
                      @focus="
                        form.errors.clear(
                          'services.' + key + '.filters.' + filterKey + '.field'
                        )
                      "
                      placeholder="Field"
                      filterable
                    >
                      <el-option-group
                        v-for="(group, key) in fields.filters[
                          service.metricsKey
                        ]"
                        :key="key"
                        :label="key"
                      >
                        <el-option
                          v-for="metric in group"
                          :key="metric.field_id"
                          :label="metric.name"
                          :value="metric.field_id"
                        ></el-option>
                      </el-option-group>
                    </el-select>
                  </modal-select>
                </el-col>

                <el-col
                  class="el-form-item"
                  :class="{
                    'is-error': form.errors.has(
                      'services.' + key + '.filters.' + filterKey + '.operator'
                    ),
                  }"
                  :span="6"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <modal-select>
                    <el-select
                      v-model="filter.operator"
                      :style="{ width: '100%' }"
                      @focus="
                        form.errors.clear(
                          'services.' +
                            key +
                            '.filters.' +
                            filterKey +
                            '.operator'
                        )
                      "
                      placeholder="Operator"
                      filterable
                    >
                      <el-option label="Equals" value="equals"></el-option>
                      <el-option
                        label="Does Not Equal"
                        value="does_not_equal"
                      ></el-option>
                      <el-option label="Contains" value="contains"></el-option>
                      <el-option
                        label="Does Not Contain"
                        value="does_not_contain"
                      ></el-option>
                    </el-select>
                  </modal-select>
                </el-col>

                <el-col
                  class="el-form-item"
                  :class="{
                    'is-error': form.errors.has(
                      'services.' + key + '.filters.' + filterKey + '.value'
                    ),
                  }"
                  :span="7"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <el-input
                    v-model="filter.value"
                    type="text"
                    placeholder="Value"
                    @focus="
                      form.errors.clear(
                        'services.' + key + '.filters.' + filterKey + '.value'
                      )
                    "
                  ></el-input>
                </el-col>

                <el-col
                  class="el-form-item"
                  :class="{
                    'is-error': form.errors.has(
                      'services.' + key + '.filters.' + filterKey + '.andOr'
                    ),
                  }"
                  :span="3"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <modal-select v-if="filterKey !== service.filters.length - 1">
                    <el-select
                      v-model="filter.andOr"
                      :style="{ width: '100%' }"
                      @focus="
                        form.errors.clear(
                          'services.' + key + '.filters.' + filterKey + '.andOr'
                        )
                      "
                    >
                      <el-option label="And" value="and"></el-option>
                      <el-option label="Or" value="or"></el-option>
                    </el-select>
                  </modal-select>
                  <div v-else>&nbsp;</div>
                </el-col>

                <el-col :span="1" style="padding-left: 5px; text-align: center">
                  <a href="javascript:void(0)">
                    <font-awesome-icon
                      icon="trash"
                      class="icon"
                      fixed-width
                      @click="removeFilter(key, filterKey)"
                    ></font-awesome-icon>
                  </a>
                </el-col>
              </el-row>
              <el-row
                v-if="
                  form.errors.has(
                    'services.' + key + '.filters.' + filterKey + '.field'
                  ) ||
                  form.errors.has(
                    'services.' + key + '.filters.' + filterKey + '.operator'
                  ) ||
                  form.errors.has(
                    'services.' + key + '.filters.' + filterKey + '.value'
                  )
                "
              >
                <div class="el-form-item__error">
                  <span
                    v-if="
                      form.errors.has(
                        'services.' + key + '.filters.' + filterKey + '.field'
                      )
                    "
                    >{{
                      form.errors.first(
                        "services." + key + ".filters." + filterKey + ".field"
                      )
                    }}</span
                  >
                  <span
                    v-else-if="
                      form.errors.has(
                        'services.' +
                          key +
                          '.filters.' +
                          filterKey +
                          '.operator'
                      )
                    "
                    >{{
                      form.errors.first(
                        "services." +
                          key +
                          ".filters." +
                          filterKey +
                          ".operator"
                      )
                    }}</span
                  >
                  <span
                    v-else-if="
                      form.errors.has(
                        'services.' + key + '.filters.' + filterKey + '.value'
                      )
                    "
                    >{{
                      form.errors.first(
                        "services." + key + ".filters." + filterKey + ".value"
                      )
                    }}</span
                  >
                </div>
              </el-row>
            </div>
            <el-row style="text-align: right">
              <el-button type="primary" @click="addFilter(service.filters)"
                >Add</el-button
              >
            </el-row>
          </el-form-item>
        </div>
      </div>

      <hr />

      <el-form-item label="Update Data" v-if="existing !== null">
        <el-checkbox v-model="update"> Update Statistics </el-checkbox>
        <modal-select v-if="update">
          <el-select
            v-model="toUpdate"
            :style="{ width: '100%' }"
            clearable
            multiple
            placeholder="All Services"
            @focus="form.errors.clear('toUpdate')"
            v-input-focus
          >
            <el-option-group label="Display">
              <el-option
                label="Google Display"
                value="google-display"
                v-if="services.googleDisplay.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon
                >Google
              </el-option>
              <el-option
                label="Facebook Display"
                value="facebook-display"
                v-if="services.facebookDisplay.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'facebook-f']"
                  fixed-width
                ></font-awesome-icon
                >Facebook
              </el-option>
            </el-option-group>
            <el-option-group label="SEM">
              <el-option
                label="Google SEM"
                value="google-sem"
                v-if="services.googleSem.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon
                >Google
              </el-option>
              <el-option
                label="Bing SEM"
                value="bing-sem"
                v-if="services.bingSem.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'microsoft']"
                  fixed-width
                ></font-awesome-icon
                >Bing
              </el-option>
            </el-option-group>
            <el-option-group label="Video">
              <el-option
                label="Google Video"
                value="google-video"
                v-if="services.googleVideo.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon
                >Google
              </el-option>
              <el-option
                label="Facebook Video"
                value="facebook-video"
                v-if="services.facebookVideo.selected"
              >
                <font-awesome-icon
                  :icon="['fab', 'facebook-f']"
                  fixed-width
                ></font-awesome-icon
                >Facebook
              </el-option>
            </el-option-group>
          </el-select>
        </modal-select>
        <p class="help-text">
          If checked, only the above above selected services will fetch new
          data. Leave empty for all services. If unchecked, no data will be
          fetched. If you are only updating some services to lower API calls.
        </p>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
        :disabled="loading || !canSubmit"
        >Submit</el-button
      >
    </el-form>
  </el-card>
</template>

<style lang="scss">
.el-select__tags {
  .el-select__input {
    margin-left: 0;
  }
}

.supermetrics-service-dropdown,
.supermetrics-service-select .el-input__suffix {
  display: none;
}
</style>

<script>
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerSupermetricsForm",

  mixins: [TrackerServiceLogos],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      serviceKeys: {
        "google-display": "googleDisplay",
        "google-video": "googleVideo",
        "google-sem": "googleSem",
        "facebook-display": "facebookDisplay",
        "facebook-video": "facebookVideo",
        "bing-sem": "bingSem",
      },
      services: {
        googleDisplay: {
          name: "Google Display",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "AW",
          accountInput: "numeric",
        },
        googleVideo: {
          name: "Google Video",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "AW",
          accountInput: "numeric",
        },
        googleSem: {
          name: "Google SEM",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "AW",
          accountInput: "numeric",
        },
        facebookDisplay: {
          name: "Facebook Display",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "FA",
          accountInput: "numeric",
        },
        facebookVideo: {
          name: "Facebook Video",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "FA",
          accountInput: "numeric",
        },
        bingSem: {
          name: "Bing SEM",
          selected: false,
          accounts: [],
          metrics: {
            impressions: {
              field: "",
              hourly: true,
            },
            clicks: {
              field: "",
              hourly: true,
            },
            conversions: {
              field: "",
              hourly: true,
            },
            cost: {
              field: "",
              hourly: true,
            },
          },
          filters: [],
          startDate: "",
          metricsKey: "AC",
          accountInput: "email",
        },
      },
      update: true,
      toUpdate: [],
      fields: {},
      accounts: {},
      form: new Form(),
      loading: false,
      pickerOptions: {
        disabledDate: (date) => {
          return date.getTime() >= Date.now()
        },
        firstDayOfWeek: 7,
      },
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      return "tracker/" + this.company.slug + "/supermetrics/update"
    },
    canSubmit() {
      return this.$_.find(this.services, ["selected", true]) !== undefined
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    submit() {
      this.loading = true

      let formData = {
        services: {},
      }

      if (this.services.googleDisplay.selected) {
        formData.services.googleDisplay = this.services.googleDisplay
      }
      if (this.services.googleSem.selected) {
        formData.services.googleSem = this.services.googleSem
      }
      if (this.services.googleVideo.selected) {
        formData.services.googleVideo = this.services.googleVideo
      }
      if (this.services.facebookDisplay.selected) {
        formData.services.facebookDisplay = this.services.facebookDisplay
      }
      if (this.services.facebookVideo.selected) {
        formData.services.facebookVideo = this.services.facebookVideo
      }
      if (this.services.bingSem.selected) {
        formData.services.bingSem = this.services.bingSem
      }

      if (this.existing !== null) {
        formData.update = this.update
        formData.toUpdate = this.toUpdate
      } else {
        formData.update = true
        formData.toUpdate = []
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-supermetrics-form")
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    addFilter(filters) {
      let index = filters.length ? filters[filters.length - 1].key + 1 : 1
      filters.push({
        key: index,
        field: "",
        operator: "",
        value: "",
        andOr: "and",
      });
    },
    removeFilter(service, key) {
      this.services[service].filters.splice(key, 1);
    },
    async init() {
      this.loading = true
      await this.axios.get("supermetrics/fields").then(({ data }) => {
        this.fields = data.data
      })
      await this.axios.get("supermetrics/accounts").then(({ data }) => {
        this.accounts = data.data
      })

      if (this.existing !== null) {
        this.$_.forEach(this.existing, (item, key) => {
          let serviceKey = this.serviceKeys[key]
          this.services[serviceKey].selected = true
          this.services[serviceKey].accounts = item.accounts
          this.services[serviceKey].metrics.impressions.field = item.impressions
          this.services[serviceKey].metrics.conversions.field = item.conversions
          this.services[serviceKey].metrics.cost.field = item.cost
          this.services[serviceKey].metrics.clicks.field = item.clicks
          this.services[serviceKey].metrics.impressions.hourly =
            item.impressions_hourly
          this.services[serviceKey].metrics.conversions.hourly =
            item.conversions_hourly
          this.services[serviceKey].metrics.cost.hourly = item.cost_hourly
          this.services[serviceKey].metrics.clicks.hourly = item.clicks_hourly
          this.services[serviceKey].filters = item.filters
          this.services[serviceKey].startDate = item.start_date
        })
      } else {
        this.services["googleDisplay"].metrics.impressions.field = "Impressions"
        this.services["googleDisplay"].metrics.clicks.field = "Clicks"
        this.services["googleDisplay"].metrics.conversions.field =
          "Conversionsmanyperclick"
        this.services["googleDisplay"].metrics.cost.field = "Cost"

        this.services["googleSem"].metrics.impressions.field = "Impressions"
        this.services["googleSem"].metrics.clicks.field = "Clicks"
        this.services["googleSem"].metrics.conversions.field =
          "Conversionsmanyperclick"
        this.services["googleSem"].metrics.cost.field = "Cost"

        this.services["googleVideo"].metrics.impressions.field = "Impressions"
        this.services["googleVideo"].metrics.clicks.field = "Clicks"
        this.services["googleVideo"].metrics.conversions.field = "VideoViews"
        this.services["googleVideo"].metrics.cost.field = "Cost"

        this.services["facebookDisplay"].metrics.impressions.field =
          "impressions"
        this.services["facebookDisplay"].metrics.clicks.field = "link_clicks"
        this.services["facebookDisplay"].metrics.conversions.field =
          "onsite_conversion.lead_grouped"
        this.services["facebookDisplay"].metrics.cost.field = "cost"

        this.services["facebookVideo"].metrics.impressions.field = "impressions"
        this.services["facebookVideo"].metrics.clicks.field = "link_clicks"
        this.services["facebookVideo"].metrics.conversions.field =
          "video_thruplay_watched_actions"
        this.services["facebookVideo"].metrics.conversions.hourly = false
        this.services["facebookVideo"].metrics.cost.field = "cost"

        this.services["bingSem"].metrics.impressions.field = "Impressions"
        this.services["bingSem"].metrics.clicks.field = "Clicks"
        this.services["bingSem"].metrics.conversions.field = "Conversions"
        this.services["bingSem"].metrics.cost.field = "Spend"
      }

      this.loading = false
    },
  },

  created() {},
  mounted() {
    this.init()
  },
}
</script>
