<template>
  <div>
    <div class="breadcrumb-item">
      <router-link :to="{ name: 'tracker' }"> Cometads Tracker </router-link>
    </div>
    <div class="breadcrumb-sep">
      <font-awesome-icon icon="angle-right" full-width></font-awesome-icon>
    </div>
    <div class="breadcrumb-item breadcrumb-tracker-type">
      <el-dropdown
        placement="bottom-start"
        size="small"
        trigger="click"
        @command="goToTrackerType"
      >
        <span class="el-dropdown-link">
          {{ !trackerType ? "All Data" : formattedTrackerType }}
          <font-awesome-icon
            icon="caret-down"
            style="margin-left: 0.25rem"
          ></font-awesome-icon>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="sem"
            v-if="'sem' in company.tracker.services"
          >
            SEM
          </el-dropdown-item>
          <el-dropdown-item
            command="display"
            v-if="'display' in company.tracker.services"
          >
            Display
          </el-dropdown-item>
          <el-dropdown-item
            command="video"
            v-if="'video' in company.tracker.services"
          >
            Social Video
          </el-dropdown-item>
          <el-dropdown-item
            command="mail"
            v-if="'mail' in company.tracker.services"
          >
            Mail
          </el-dropdown-item>
          <el-dropdown-item
            command="tv"
            v-if="'tv' in company.tracker.services"
          >
            TV
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="breadcrumb-sep" v-if="trackerType">
      <font-awesome-icon icon="angle-right" full-width></font-awesome-icon>
    </div>
    <div class="breadcrumb-item breadcrumb-tracker-service" v-if="trackerType">
      <el-dropdown
        placement="bottom-start"
        size="small"
        trigger="click"
        @command="goToTrackerService"
        class="tracker-service-dropdown"
      >
        <span class="el-dropdown-link">
          <span v-html="formattedTrackerService"></span>
          <font-awesome-icon
            icon="caret-down"
            style="margin-left: 0.25rem"
          ></font-awesome-icon>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="trackerType === 'sem'">
          <el-dropdown-item
            command="google"
            v-if="'google-sem' in company.tracker.services"
          >
            Google
          </el-dropdown-item>
          <el-dropdown-item
            command="bing"
            v-if="'bing-sem' in company.tracker.services"
          >
            Bing
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-if="trackerType === 'display'">
          <el-dropdown-item
            command="google"
            v-if="'google-display' in company.tracker.services"
          >
            Google
          </el-dropdown-item>
          <el-dropdown-item
            command="google-remarketing"
            v-if="
              'google-display' in company.tracker.services &&
              company.tracker.remarketing
            "
          >
            Google Remarketing
          </el-dropdown-item>
          <el-dropdown-item
            command="facebook"
            v-if="'facebook-display' in company.tracker.services"
            >Facebook
          </el-dropdown-item>
          <el-dropdown-item
            command="facebook-remarketing"
            v-if="
              'facebook-display' in company.tracker.services &&
              company.tracker.remarketing
            "
            >Facebook Remarketing
          </el-dropdown-item>
          <el-dropdown-item
            command="geofencing"
            v-if="'simplifi-display' in company.tracker.services"
            >Zone Geofencing
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-if="trackerType === 'video'">
          <el-dropdown-item
            command="google"
            v-if="'google-video' in company.tracker.services"
          >
            YouTube
          </el-dropdown-item>
          <el-dropdown-item
            command="google-remarketing"
            v-if="
              'google-video' in company.tracker.services &&
              company.tracker.remarketing
            "
          >
            YouTube Remarketing
          </el-dropdown-item>
          <el-dropdown-item
            command="facebook"
            v-if="'facebook-video' in company.tracker.services"
          >
            Facebook
          </el-dropdown-item>
          <el-dropdown-item
            command="facebook-remarketing"
            v-if="
              'facebook-video' in company.tracker.services &&
              company.tracker.remarketing
            "
            >Facebook Remarketing
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-if="trackerType === 'mail'">
          <el-dropdown-item
            command="siteimpact"
            v-if="'siteimpact-mail' in company.tracker.services"
          >
            Leased List
          </el-dropdown-item>
          <el-dropdown-item
            command="leasedlist"
            v-if="'emailresultz-mail' in company.tracker.services"
          >
            Leased List
          </el-dropdown-item>
          <el-dropdown-item
            command="houselist"
            v-if="'mailchimp-mail' in company.tracker.services"
          >
            House List
          </el-dropdown-item>
          <el-dropdown-item
            command="constantcontact"
            v-if="'constantcontact-mail' in company.tracker.services"
          >
            House List
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-if="trackerType === 'tv'">
          <el-dropdown-item
            command="geofencing"
            v-if="'simplifi-tv' in company.tracker.services"
          >
            Streaming / Geofencing
          </el-dropdown-item>
          <el-dropdown-item
            command="streaming"
            v-if="'tremor-tv' in company.tracker.services"
          >
            Streaming
          </el-dropdown-item>
          <el-dropdown-item
            command="settop"
            v-if="'settop-tv' in company.tracker.services"
          >
            Set Top / VOD
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.tracker-service-dropdown {
  > span {
    height: 100%;
    display: inline-flex;
    align-items: center;

    > span {
      display: inline-flex;
      align-items: center;

      .breadcrumb-logo {
        width: 16px;
        height: 16px;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>

<script>
import svg from "@/theme/svg"
import { mapGetters } from "vuex"

export default {
  name: "TrackerBreadcrumbs",

  mixins: [],
  components: {},

  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    trackerType() {
      return this.$route.params.type
    },
    trackerService() {
      return this.$route.params.service
    },
    formattedTrackerType() {
      if (!this.trackerType) return "All Data"
      if (this.trackerType === "sem") return "SEM"
      if (this.trackerType === "display") return "Display"
      if (this.trackerType === "mail") return "Email"
      if (this.trackerType === "tv") return "Advanced TV"
      if (this.trackerType === "video") return "Social Video"
      return ""
    },
    formattedTrackerService() {
      if (!this.trackerService) return "All Data"
      if (this.trackerService === "bing") {
        return (
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1204 1204" class="breadcrumb-logo">` +
          this.logos.bing +
          `</svg> Bing`
        )
      } else if (this.trackerService === "facebook") {
        return (
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="breadcrumb-logo">` +
          this.logos.facebook +
          `</svg> Facebook`
        )
      } else if (this.trackerService === "facebook-remarketing") {
        return (
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="breadcrumb-logo">` +
          this.logos.facebook +
          `</svg> Facebook Remarketing`
        )
      } else if (
        this.trackerService === "google" &&
        this.trackerType === "display"
      ) {
        return (
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="breadcrumb-logo">` +
          this.logos.google +
          `</svg> Google`
        )
      } else if (
        this.trackerService === "google-remarketing" &&
        this.trackerType === "display"
      ) {
        return (
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="breadcrumb-logo">` +
          this.logos.google +
          `</svg> Google Remarketing`
        )
      } else if (
        this.trackerService === "google" &&
        this.trackerType === "video"
      ) {
        return `YouTube`
      } else if (
        this.trackerService === "google-remarketing" &&
        this.trackerType === "video"
      ) {
        return `YouTube Remarketing`
      } else if (this.trackerService === "siteimpact") {
        return `Leased List`
      } else if (this.trackerService === "mailchimp" || this.trackerService === "houselist") {
        return `House List`
      } else if (
        (this.trackerService === "simplifi" || this.trackerService === "geofencing") &&
        this.trackerType === "display"
      ) {
        return `Zone Geofencing`
      } else if (
        (this.trackerService === "simplifi" || this.trackerService === "geofencing") &&
        this.trackerType === "tv"
      ) {
        return `Geographic Targeted`
      } else if (
        (this.trackerService === "tremor" || this.trackerService === "streaming") &&
        this.trackerType === "tv"
      ) {
        return `Demographic Targeted`
      } else if (
        this.trackerService === "settop" &&
        this.trackerType === "tv"
      ) {
        return `Set Top`
      } else if (
        this.trackerService === "constantcontact" &&
        this.trackerType === "mail"
      ) {
        return `House List`
      } else if (
        (this.trackerService === "emailresultz" || this.trackerService === "leasedlist") &&
        this.trackerType === "mail"
      ) {
        return `Leased List`
      }
      return ""
    },
    logos() {
      return {
        bing: svg.bing,
        google: svg.google,
        facebook: svg.facebook,
      }
    },
  },
  watch: {},

  methods: {
    goToTrackerType(value) {
      this.$router.push({
        name: "tracker",
        params: {
          type: value,
        },
      })
    },
    goToTrackerService(value) {
      this.$router.push({
        name: "tracker",
        params: {
          type: this.$route.params.type,
          service: value,
        },
      })
    },
  },

  created() {},
  mounted() {},
}
</script>
