<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Create A User</span>
      <el-button type="text" @click="$modal.hide('user-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="create"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="user.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>
      <el-form-item
        :error="form.errors.first('email') ? form.errors.first('email') : null"
        label="Email"
        required
      >
        <el-input
          v-model="user.email"
          type="email"
          placeholder="Email"
          @focus="form.errors.clear('email')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :error="
          form.errors.first('password') ? form.errors.first('password') : null
        "
        label="Password"
      >
        <el-col :span="11" style="padding-right: 0.5rem">
          <el-input
            v-model="user.password"
            type="password"
            placeholder="Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
        <el-col :span="11" style="padding-left: 0.5rem">
          <el-input
            v-model="user.password_confirmation"
            type="password"
            placeholder="Confirm Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
        <el-col :span="2" align="center">
          <el-tooltip
            class="item"
            effect="dark"
            content="Optional. Leave blank and a registration email will be sent to the user."
            placement="bottom-end"
          >
            <font-awesome-icon
              icon="question-circle"
              fixed-width
            ></font-awesome-icon>
          </el-tooltip>
        </el-col>
      </el-form-item>
      <el-form-item
        :error="form.errors.first('role') ? form.errors.first('role') : null"
        label="Role"
        required
      >
        <modal-select>
          <el-select
            v-model="user.role"
            :style="{ width: '100%' }"
            placeholder="Choose a Role..."
            @focus="form.errors.clear('role')"
          >
            <el-option
              v-for="item in roles"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>
      <el-form-item
        :error="
          form.errors.first('companies') ? form.errors.first('companies') : null
        "
        label="Companies"
      >
        <modal-select>
          <el-select
            v-model="user.companies"
            :style="{ width: '100%' }"
            multiple
            placeholder="Assign Companies..."
            @focus="form.errors.clear('companies')"
          >
            <el-option
              v-for="item in companies"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>
      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "UserCreateForm",

  mixins: [],

  data() {
    return {
      user: {
        name: "",
        email: "",
        role: "",
        companies: [],
        password: "",
        password_confirmation: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      roles: "users/roles",
      companies: "companies/list",
    }),
  },

  methods: {
    ...mapActions({
      addUser: "users/add",
    }),
    create() {
      this.loading = true

      this.axios
        .post("users/create", this.user)
        .then((response) => {
          this.addUser(response.data.data)
          this.$modal.hide("user-create-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>
