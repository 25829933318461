<template>
  <div>
    <tracker-supermetrics-counts :counts="counts" />
    <el-row class="tracker-settings-header">
      <el-col class="tracker-settings-header-name text-truncate">
        <span>{{ company.name }} Tracker</span>
      </el-col>
      <el-col class="tracker-settings-header-container">
        <el-button type="text" size="medium" @click="refreshData(false)">
          <font-awesome-icon
            icon="sync-alt"
            :spin="loading"
            fixed-width
          ></font-awesome-icon>
          Refresh Data
        </el-button>
        <router-link
          :to="{ name: 'admin_tracker' }"
          class="el-button el-button--primary el-button--medium"
        >
          <font-awesome-icon icon="arrow-left" fixed-width></font-awesome-icon>
          All Company Trackers
        </router-link>
      </el-col>
    </el-row>

    <template v-if="data && !data.active">
      <el-card>
        <el-row>
          <el-alert
            :title="
              company.name +
              ' does not have a tracker set up. Please do so using the form below.'
            "
            type="info"
            :closable="false"
          ></el-alert>
        </el-row>
        <el-row type="flex" justify="center" style="margin-top: 1rem">
          <el-col :md="16">
            <tracker-create-form :existing="company"></tracker-create-form>
          </el-col>
        </el-row>
      </el-card>
    </template>
    <template v-else>
      <tracker-company-settings
        v-loading="loading"
        :company="company"
        :data="data ? data : null"
        style="margin-bottom: 1rem"
      ></tracker-company-settings>

      <tracker-company-integrations
        :data="data ? data : null"
        v-loading="loading"
        style="margin-bottom: 1rem"
      ></tracker-company-integrations>

      <tracker-company-history
        v-loading="loading"
        :data="data ? data.history : null"
        style="margin-bottom: 1rem"
      ></tracker-company-history>

      <tracker-company-campaigns
        v-loading="loading"
        :company="company"
        :campaigns="data ? data.campaigns : []"
        style="margin-bottom: 1rem"
      ></tracker-company-campaigns>
    </template>
  </div>
</template>

<style lang="scss">
</style>

<script>
import { mapGetters, mapActions } from "vuex"
import TrackerSupermetricsCounts from "@/partials/Tracker/TrackerSupermetricsCounts"
import TrackerCreateForm from "@/components/Forms/TrackerCreateForm"
import TrackerCompanySettings from "@/partials/Tracker/TrackerCompanySettings"
import TrackerCompanyCampaigns from "@/partials/Tracker/TrackerCompanyCampaigns"
import TrackerCompanyHistory from "@/partials/Tracker/TrackerCompanyHistory"
import TrackerCompanyIntegrations from "@/partials/Tracker/TrackerCompanyIntegrations"

export default {
  name: "TrackerCompanyAdmin",

  mixins: [],
  components: {
    TrackerCreateForm,
    TrackerCompanySettings,
    TrackerCompanyCampaigns,
    TrackerCompanyHistory,
    TrackerCompanyIntegrations,
    TrackerSupermetricsCounts,
  },

  props: {},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      companies: "companies/list",
      data: "tracker/currentAdmin",
      counts: "tracker/supermetricsCounts",
    }),
    company() {
      let index = this.$_.findIndex(this.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.companies[index]
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
    data(newVal) {
      if (newVal === null) {
        this.loading = true
      } else {
        this.loading = false
      }
    },
  },

  methods: {
    ...mapActions({
      refresh: "tracker/getAdmin",
      updateCompany: "tracker/updateAdmin",
    }),
    routeChange() {
      if (!this.company) {
        // this.$router.replace('/404');
      } else {
        document.title =
          this.$root.app.name + " - " + this.company.name + " Tracker Settings"
        this.refresh()
      }
    },
    refreshData() {
      this.refresh()
    },
  },

  created() {},
  mounted() {
    this.routeChange()
  },
}
</script>
