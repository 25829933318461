<template>
  <div>
    <el-alert
      title="No campaigns for this tracker."
      type="info"
      :closable="false"
      v-if="isEmpty"
    ></el-alert>

    <data-tables
      v-else
      :data="current ? current : []"
      :pagination-props="paginationDef"
      :table-props="tableProps"
      :show-action-bar="false"
      :filters="[searchDef]"
      style="width: 100%"
    >
      <el-row class="table-header" slot="tool">
        <el-col class="table-header-name"></el-col>
        <el-col class="table-header-container">
          <div style="margin-right: 0.5rem">
            <el-select v-model="selectedService" size="small">
              <el-option
                v-for="item in services"
                :key="item"
                :label="getServiceLabel(item)"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div style="flex: 1">
            <el-input
              v-model="searchDef.value"
              placeholder="Search by Name..."
              size="small"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </el-col>
      </el-row>

      <el-table-column label="Status" width="60" align="center">
        <template slot-scope="scope">
          <font-awesome-icon
            icon="circle"
            :class="{ success: scope.row.active, danger: !scope.row.active }"
            :style="{ opacity: scope.row.visible ? '1' : '.25' }"
          ></font-awesome-icon>
        </template>
      </el-table-column>

      <el-table-column
        prop="display_name"
        label="Name"
        min-width="150"
        show-overflow-tooltip
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ scope.row.display_name }}
          <span
            v-if="scope.row.name && scope.row.custom_name"
            class="text-light"
            >({{ scope.row.name }})</span
          >
        </template>
      </el-table-column>

      <el-table-column label="Start" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.custom_start">
            <el-tooltip
              effect="dark"
              :content="
                'Original Value: ' +
                (scope.row.start
                  ? dateTimeFormatString(
                      scope.row.start,
                      'MM/DD/YY',
                      'YYYY-MM-DD'
                    )
                  : '---'
                ).toString()
              "
              placement="bottom"
            >
              <span>
                {{
                  dateTimeFormatString(
                    scope.row.custom_start,
                    "MM/DD/YY",
                    "YYYY-MM-DD"
                  )
                }}
                <font-awesome-icon
                  icon="asterisk"
                  fixed-width
                ></font-awesome-icon>
              </span>
            </el-tooltip>
          </span>
          <span v-else>
            {{
              scope.row.start
                ? dateTimeFormatString(
                    scope.row.start,
                    "MM/DD/YY",
                    "YYYY-MM-DD"
                  )
                : "---"
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="End" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.custom_end">
            <el-tooltip
              effect="dark"
              :content="
                'Original Value: ' +
                (scope.row.end
                  ? dateTimeFormatString(
                      scope.row.end,
                      'MM/DD/YY',
                      'YYYY-MM-DD'
                    )
                  : '---'
                ).toString()
              "
              placement="bottom"
            >
              <span>
                {{
                  dateTimeFormatString(
                    scope.row.custom_end,
                    "MM/DD/YY",
                    "YYYY-MM-DD"
                  )
                }}
                <font-awesome-icon
                  icon="asterisk"
                  fixed-width
                ></font-awesome-icon>
              </span>
            </el-tooltip>
          </span>
          <span v-else>
            {{
              scope.row.start
                ? dateTimeFormatString(scope.row.end, "MM/DD/YY", "YYYY-MM-DD")
                : "---"
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="updated_at"
        label="Last Updated"
        width="200"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ dateTimeFormat(scope.row.updated_at) }}
        </template>
      </el-table-column>

      <el-table-column label="" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="padding: 0"
            @click="openTrackerCampaignEditModal(scope.row)"
            >Modify</el-button
          >
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>


<style lang="scss">
</style>


<script>
import DateTime from "@/mixins/DateTime"
import TrackerCampaignEditForm from "@/components/Forms/TrackerCampaignEditForm"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"

export default {
  name: "TrackerCampaignTable",

  mixins: [DateTime, TrackerServiceLogos],
  components: {},

  props: {
    campaigns: {
      required: true,
    },
    company: {
      required: true,
    },
  },

  data() {
    return {
      searchDef: {
        value: "",
        prop: "display_name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "updated_at",
          order: "descending",
        },
      },
      selectedService: null,
    }
  },
  computed: {
    data() {
      if (!this.campaigns) return
      return this.$_.groupBy(
        this.$_.orderBy(
          this.$_.map(this.campaigns, (item) => {
            return this.$_.merge(item, {
              display_name: item.custom_name ? item.custom_name : item.name,
            })
          }),
          (item) => {
            return item.updated_at
          }
        ),
        (item) => {
          return item.service
        }
      )
    },
    isEmpty() {
      if (!this.campaigns) return true
      return this.campaigns.length === 0
    },
    services() {
      if (this.isEmpty) return
      let keys = Object.keys(this.data)
      if (this.selectedService === null) {
        // eslint-disable-next-line
        this.selectedService = keys[0]
      }
      return Object.keys(this.data)
    },
    current() {
      if (this.isEmpty || !this.selectedService) return []
      return this.data[this.selectedService]
    },
  },

  methods: {
    openTrackerCampaignEditModal(row) {
      this.$modal.show(
        TrackerCampaignEditForm,
        {
          existing: row,
          company: this.company.slug,
        },
        {
          name: "tracker-campaign-edit-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    getServiceLabel(service) {
      if (service === "google-display") return "Google Display"
      if (service === "google-sem") return "Google SEM"
      if (service === "google-video") return "Google Video"
      if (service === "facebook-display") return "Facebook Display"
      if (service === "facebook-video") return "Facebook Video"
      if (service === "bing-sem") return "Bing SEM"
      if (service === "siteimpact-mail") return "SiteImpact"
      if (service === "tremor-tv") return "Tremor"
      if (service === "simplifi-tv") return "Simpli.fi TV"
      if (service === "simplifi-display") return "Simpli.fi Display"
      if (service === "mailchimp-mail") return "MailChimp"
      if (service === "emailresultz-mail") return "Email Resultz"
      if (service === "constantcontact-mail") return "Constant Contact"
      if (service === "settop-tv") return "Set Top"
    },
  },

  watch: {},

  created() {},
}
</script>
