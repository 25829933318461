<template>
  <data-tables
    :data="data === null ? [] : data"
    :pagination-props="paginationDef"
    :table-props="tableProps"
    :show-action-bar="false"
    :filters="[searchDef]"
    style="width: 100%"
    v-loading="loading"
    class="tracker-table"
  >
    <el-row class="table-header" slot="tool">
      <el-col class="table-header-name">
        <span>Tracker Settings</span>
      </el-col>
      <el-col class="table-header-container">
        <div style="flex: 1">
          <el-input
            v-model="searchDef.value"
            placeholder="Search By Company Name..."
            size="small"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div style="flex: 0; margin-left: 0.5rem">
          <el-button @click="refresh" type="primary" size="small">
            <font-awesome-icon
              icon="sync-alt"
              :spin="loading"
              fixed-width
            ></font-awesome-icon>
            Refresh Table
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-table-column label="" width="60" align="center">
      <template slot-scope="scope">
        <span v-if="!scope.row.tracker.active">
          <el-tooltip
            effect="dark"
            content="Tracker inactive."
            placement="bottom"
          >
            <font-awesome-icon
              icon="times-circle"
              fixed-width
              style="color: #909399"
            ></font-awesome-icon>
          </el-tooltip>
        </span>
        <span v-else-if="scope.row.tracker.paused">
          <el-tooltip
            effect="dark"
            content="Tracker Paused."
            placement="bottom"
          >
            <font-awesome-icon
              icon="pause-circle"
              fixed-width
              style="color: #e6a23c"
            ></font-awesome-icon>
          </el-tooltip>
        </span>
        <span v-else>
          <el-tooltip
            effect="dark"
            content="Tracker active."
            placement="bottom"
          >
            <font-awesome-icon
              icon="check-circle"
              fixed-width
              style="color: #67c23a"
            ></font-awesome-icon>
          </el-tooltip>
        </span>
      </template>
    </el-table-column>

    <el-table-column
      prop="name"
      label="Company"
      sortable="custom"
      min-width="150"
      show-overflow-tooltip
    ></el-table-column>

    <el-table-column label="" width="250" class-name="tracker-services-cell">
      <template slot-scope="scope">
        <template v-if="scope.row.tracker.active">
          <img
            :src="getServiceLogo('google-display')"
            class="service-logo"
            :class="{
              inactive:
                !('google-display' in scope.row.tracker.services) &&
                !('google-sem' in scope.row.tracker.services) &&
                !('google-video' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('bing-sem')"
            class="service-logo"
            :class="{
              inactive: !('bing-sem' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('facebook-display')"
            class="service-logo"
            :class="{
              inactive:
                !('facebook-display' in scope.row.tracker.services) &&
                !('facebook-video' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('simplifi-display')"
            class="service-logo"
            :class="{
              inactive:
                !('simplifi-display' in scope.row.tracker.services) &&
                !('simplifi-tv' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('siteimpact-mail')"
            class="service-logo"
            :class="{
              inactive: !('siteimpact-mail' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('mailchimp-mail')"
            class="service-logo"
            :class="{
              inactive: !('mailchimp-mail' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('tremor-tv')"
            class="service-logo"
            :class="{
              inactive: !('tremor-tv' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('emailresultz-mail')"
            class="service-logo"
            :class="{
              inactive: !('emailresultz-mail' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('constantcontact-mail')"
            class="service-logo"
            :class="{
              inactive: !('constantcontact-mail' in scope.row.tracker.services),
            }"
          />
          <img
            :src="getServiceLogo('settop-tv')"
            class="service-logo"
            :class="{
              inactive: !('settop-tv' in scope.row.tracker.services),
            }"
          />
        </template>
        &nbsp;
      </template>
    </el-table-column>

    <el-table-column label="" width="100" align="center">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'admin_tracker_company',
            params: { company: scope.row.slug },
          }"
        >
          {{ scope.row.tracker.active ? "Edit" : "Set up" }}
        </router-link>
      </template>
    </el-table-column>
  </data-tables>
</template>


<style lang="scss">
.set-up-tracker {
  text-align: center;
  p {
    margin-bottom: 0.5rem;
  }
}

.el-table__expanded-cell[class*="cell"] {
  background: #fafafa;
  padding: 1rem 2rem;
  font-size: 0.9rem;

  p {
    font-size: inherit;
  }
}
</style>


<script>
import TrackerCreateForm from "@/components/Forms/TrackerCreateForm"

import DateTime from "@/mixins/DateTime"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"

import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerTable",

  mixins: [DateTime, TrackerServiceLogos],
  components: {},

  data() {
    return {
      searchDef: {
        value: "",
        prop: "name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "name",
          order: "ascending",
        },
        rowClassName: (row) => {
          if (!row.row.tracker) return "inactive"
          return ""
        },
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      data: "companies/list",
    }),
  },

  methods: {
    ...mapActions({
      refreshCompanies: "companies/refresh",
      clearCompanies: "companies/clear",
    }),
    refresh() {
      this.loading = true
      this.clearCompanies()
      this.refreshCompanies()
    },
    openCreateTrackerModal(row) {
      this.$modal.show(
        TrackerCreateForm,
        {
          existing: row,
        },
        {
          name: "tracker-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
  },

  watch: {
    data(val) {
      val === null ? (this.loading = true) : (this.loading = false)
    },
  },

  created() {
    this.refresh()
  },
}
</script>
