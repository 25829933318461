<script>
export default {
  name: "TrackerServiceLogos",

  data() {
    return {
      typeLogos: {
        "google-display": "google-logo.png",
        "google-display-remarketing": "google-logo.png",
        "facebook-display": "facebook-logo.png",
        "facebook-display-remarketing": "facebook-logo.png",
        "google-video": "youtube_logo.png",
        "google-video-remarketing": "youtube_logo.png",
        "facebook-video": "facebook-logo.png",
        "facebook-video-remarketing": "facebook-logo.png",
        "google-sem": "google-logo.png",
        "bing-sem": "bing-logo.png",
        "siteimpact-mail": "siteimpact-logo.png",
        "mailchimp-mail": "mailchimp-logo.png",
        "lpp-display": "lpp-display.png",
        "simplifi-display": "simplifi-logo.png",
        "simplifi-tv": "simplifi-logo.png",
        "tremor-tv": "tremor-logo.png",
        "emailresultz-mail": "emailresultz-logo.png",
        "constantcontact-mail": "constantcontact-logo.png",
        "settop-tv": "settopbox.png",
        "public-google-display": "google-logo.png",
        "public-google-display-remarketing": "google-logo.png",
        "public-facebook-display": "facebook-logo.png",
        "public-facebook-display-remarketing": "facebook-logo.png",
        "public-google-video": "youtube_logo.png",
        "public-google-video-remarketing": "youtube_logo.png",
        "public-facebook-video": "facebook-logo.png",
        "public-facebook-video-remarketing": "facebook-logo.png",
        "public-google-sem": "google-logo.png",
        "public-bing-sem": "bing-logo.png",
        "public-mailchimp-mail": "house-list-logo.png",
        "public-siteimpact-mail": "leased-list-logo.png",
        "public-simplifi-display": "zone-logo.png",
        "public-simplifi-tv": "geographic-targeted.png",
        "public-tremor-tv": "demographic-targeted.png",
        "public-settop-tv": "settopbox.png",
        "public-constantcontact-mail": "house-list-logo.png",
        "public-emailresultz-mail": "leased-list-logo.png"
      },
    }
  },

  methods: {
    getServiceLogo(service, pub = false) {
      var images = require.context("@/assets/images/", false, /\.png$/)
      let key = service;
      if(pub) key = 'public-' + key

      return images("./" + this.typeLogos[key])
    },
  },
}
</script>
