import App from '@/App.vue'
import axios from 'axios'

export default {
  listUsers(cb) {
    axios.get('users/list')
      .then(response => cb(response.data.users))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  },
  listRoles(cb) {
    axios.get('users/roles/list')
      .then(response => cb(response.data.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
