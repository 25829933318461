import App from '@/App.vue'
import axios from 'axios'

export default {
  listCampaigns(company, date, cb) {
    axios.get('campaigns/' + company + '/' + date + '/list/')
      .then(response => cb(response.data.campaigns))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
