<template>
  <div class="company-home-container">
    <div class="column assets">
      <router-link :to="{ name: 'assets', params: { company: company.slug } }">
        <div class="content">
          <div class="logo">
            <font-awesome-icon
              icon="paint-brush"
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="name">
            <span> Creative Assets </span>
          </div>
        </div>
      </router-link>
    </div>

    <div
      class="column cometads"
      v-if="company.tracker && !$_.isEmpty(company.tracker.services)"
    >
      <router-link :to="{ name: 'tracker', params: { company: company.slug } }">
        <div class="content">
          <div class="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="caLogo"
              fill="rgba(240, 189, 0, 0.9)"
            ></svg>
          </div>
          <div class="name">
            <span> CometAds Tracker </span>
          </div>
        </div>
      </router-link>
    </div>

    <div class="column support">
      <router-link
        :to="{ name: 'company_support', params: { comapny: company.slug } }"
      >
        <div class="content">
          <div class="logo">
            <font-awesome-icon
              :icon="['far', 'life-ring']"
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="name">
            <span> Manage Requests </span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.company-home-container {
  display: flex;
  justify-content: space-around;

  .column {
    flex: 1;
    max-width: 250px;
    background-color: #fefefe;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    margin: 0 10px 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s, color 0.2s, fill 0.2s;

    a {
      color: inherit;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 1rem;
        position: relative;
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }
        > img,
        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: auto;
          max-height: 150px;
          height: 100%;
          margin: auto;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        font-size: 1.125rem;
        letter-spacing: 0.05rem;
        flex-grow: 1;
        align-items: center;
        background: #fafafa;
        text-align: center;
        span {
          margin: 0.5rem;
        }
      }
    }
  }

  .column.assets {
    color: rgba(0, 128, 255, 0.8);
  }

  .column.assets:hover,
  .column.assets:active {
    color: rgba(0, 128, 255, 1);
    box-shadow: 0 2px 2px 0 rgba(0, 128, 255, 0.5);
  }

  .column.cometads {
    color: rgba(240, 189, 0, 0.9);
    fill: rgba(240, 189, 0, 0.9);
  }

  .column.cometads:hover,
  .column.cometads:active {
    color: rgba(240, 189, 0, 1);
    fill: rgba(240, 189, 0, 1);
    box-shadow: 0 2px 2px 0 rgba(240, 189, 0, 0.5);
  }

  .column.support {
    color: rgba(227, 77, 66, 0.9);
    fill: rgba(227, 77, 66, 0.9);
  }

  .column.support:hover {
    color: rgba(227, 77, 66, 1);
    box-shadow: 0 2px 2px 0 rgba(227, 77, 66, 0.5);
  }
}
</style>

<script>
import DateTime from "@/mixins/DateTime"

import { mapGetters } from "vuex"

import svg from "@/theme/svg"

export default {
  name: "Company",

  mixins: [DateTime],

  data() {
    return {
      caLogo: svg.comet,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
    company() {
      let index = this.$_.findIndex(this.auth.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.auth.companies[index]
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
  },

  methods: {
    routeChange() {
      if (!this.company) {
        this.$router.replace("/404")
      } else {
        document.title = this.$root.app.name + " - " + this.company.name
      }
    },
  },

  created() {
    this.routeChange()
  },
}
</script>
