import api from '@/api/users'
import { findIndex } from 'lodash'

const namespaced = true

const state = {
  users: [],
  roles: []
}

// getters
const getters = {
  list: state => {
    return state.users
  },
  roles: state => {
    return state.roles
  }
}

// actions
const actions = {
  refresh({ commit }) {
    api.listUsers(users => {
      commit('setUsers', users)
    })
    api.listRoles(roles => {
      commit('setRoles', roles)
    })
  },
  add({ commit }, payload) {
    commit('addUser', payload);
  },
  update({ commit }, payload) {
    commit('updateUser', payload);
  },
  remove({ commit }, payload) {
    commit('removeUser', payload);
  },
}

// mutations
const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  addUser(state, payload) {
    state.users = [...state.users, payload]
  },
  updateUser(state, payload) {
    let index = findIndex(state.users, { slug: payload.slug });
    state.users.splice(index, 1, payload);
  },
  removeUser(state, payload) {
    let index = findIndex(state.users, { slug: payload });
    state.users.splice(index, 1);
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
