<template>
  <div>
    <template v-if="campaigns && hasPublishedAssets">
      <campaign-view
        v-if="campaign"
        :campaign="campaign"
        :collection="collection"
        :editing="editing"
      ></campaign-view>
      <campaign-view
        v-else-if="campaigns.length > 0"
        v-for="campaign in campaigns"
        :key="campaign.slug"
        :campaign="campaign"
        :collection="collection"
        :editing="editing"
      ></campaign-view>
      <div class="no-campaigns" v-else-if="campaigns.length === 0">
        <p>There are no campaigns for this month.</p>
        <p v-if="$auth.check(['admin', 'super'])">
          <transition name="fade" mode="out-in">
            <span v-if="!editing"> Please edit the page to create one. </span>
            <el-button v-else type="primary" @click="openCreateCampaignModal">
              Create a Campaign
            </el-button>
          </transition>
        </p>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.no-campaigns {
  font-size: 1rem;
  text-align: center;
}
</style>


<script>
import { mapActions, mapGetters } from "vuex"
import CampaignCreateForm from "@/components/Forms/CampaignCreateForm"
import AssetCreateForm from "@/components/Forms/AssetCreateForm"
import { EventBus } from "@/events"
import CampaignView from "@/partials/Month/Campaign"
import moment from "moment"
import debounce from "lodash/debounce"

export default {
  name: "Month",

  components: {
    CampaignView,
  },

  data() {
    return {
      editing: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      campaigns: "campaigns/list",
      collections: "collections/list",
    }),
    company() {
      let index = this.$_.findIndex(this.auth.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.auth.companies[index]
    },
    month() {
      if (this.$route.params.month !== "evergreen") {
        if (!/^[0-1][0-9]-[2][0-9]{3}$/g.test(this.$route.params.month))
          return false
      }
      return this.$route.params.month
    },
    campaign() {
      let index = this.$_.findIndex(this.campaigns, {
        slug: this.$route.params.campaign,
      })
      if (index === -1) return false
      return this.campaigns[index]
    },
    collection() {
      let index = this.$_.findIndex(this.collections, {
        slug: this.$route.params.collection,
      })
      if (index === -1) return false
      return this.collections[index]
    },
    formattedMonth() {
      if (this.$route.params.month === "evergreen") return "Evergreen"
      return moment(this.$route.params.month, "MM-YYYY").format("MMM YYYY")
    },
    hasPublishedAssets() {
      if (this.$auth.check(["admin", "super", "employee"])) return true
      if (!this.campaigns) return null
      if (this.campaigns.length === 0) return false
      let truthy = false
      this.$_.forEach(this.campaigns, (campaign) => {
        this.$_.forEach(campaign.collections, (collection) => {
          if (this.$_.some(collection.assets, "published")) {
            truthy = true
          }

          if (truthy) return false
        })

        if (truthy) return false
      })

      return truthy
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
    hasPublishedAssets: {
      handler: function (val) {
        if (
          !this.$auth.check(["super", "admin", "employee"]) &&
          val === false
        ) {
          this.$router.replace("/404")
        }
      },
    },
  },

  methods: {
    ...mapActions({
      refreshCampaigns: "campaigns/refresh",
      refreshCollections: "collections/refresh",
    }),
    routeChange() {
      this.refreshCampaigns()

      if (
        !this.company ||
        !this.month ||
        (!this.campaign && this.$route.params.campaign === "") ||
        (!this.collection && this.$route.params.collection === "")
      ) {
        this.$router.replace("/404")
      }

      document.title =
        this.$root.app.name +
        " - " +
        this.formattedMonth +
        " - " +
        this.company.name
    },
    openCreateCampaignModal() {
      this.$modal.show(
        CampaignCreateForm,
        {
          month: this.month,
        },
        {
          name: "campaign-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openCreateAssetModal() {
      this.$modal.show(
        AssetCreateForm,
        {},
        {
          name: "asset-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
  },

  created() {
    this.routeChange()
    this.refreshCollections()
  },
  mounted() {
    EventBus.$on("month-edit", (val) => {
      this.editing = val
    })
    EventBus.$on("new-campaign-modal", () => {
      this.openCreateCampaignModal()
    })
    EventBus.$on("new-asset-modal", () => {
      this.openCreateAssetModal()
    })
  },

  updated: debounce(function () {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        this.$scrollTo(this.$route.hash)
      }
    })
  }, 500), // increase to ur needs
}
</script>
