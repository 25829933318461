<template>
  <div>
    <el-card v-if="error" style="text-align: center">
      We're sorry, but you do not have access to view any companies. If you
      believe this is an error please reach out to your account representative.
    </el-card>
    <div class="companies-container" v-else>
      <company-card
        v-for="company in companies"
        :company="company"
        :key="company.slug"
      ></company-card>
    </div>
  </div>
</template>

<style lang="scss">
.companies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .company-container {
    width: calc(25% - 10px);
    background-color: #fefefe;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: box-shadow 0.2s;

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 0.5rem;
        position: relative;
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }
        > img,
        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: auto;
          max-height: 180px;
          height: 100%;
          margin: auto;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        font-size: 1.125rem;
        letter-spacing: 0.05rem;
        flex-grow: 1;
        align-items: center;
        background: #fafafa;
        text-align: center;
        span {
          margin: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .companies-container {
    .company-container {
      width: calc(50% - 10px);
    }
  }
}
</style>

<script>
import CompanyCard from "@/partials/Home/CompanyCard"
import { mapGetters } from "vuex"

export default {
  name: "HomePage",

  components: {
    CompanyCard,
  },

  data() {
    return {
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
    companies() {
      return this.auth.companies
    },
  },

  mounted() {
    if (this.auth.companies.length === 0) {
      this.error = true
    } else if (this.auth.companies.length === 1) {
      this.$router.replace({
        name: "company",
        params: { company: this.auth.companies[0].slug },
      })
    }
  },
}
</script>
